import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5E0207",
    },
    secondary: {
      main: "#c6a65d",
    },
    customStatus: {
      main: colors.red['900'],
    },
    colorDark: {
      main: "#141414",
    },
    customDanger: {
      main: "#CA0101",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    colors
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
