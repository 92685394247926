import React, { useState } from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import { GridList, GridListTile } from '@material-ui/core';
import styles from './index.style';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import { connect } from 'react-redux';
import Styles from '../../assets/css/custom.scss';
import Image from 'material-ui-image';
import { isBrowser, isMobile } from "react-device-detect";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';


function GalleryPage({ hideBredCrumb, ...otherProps }) {

  const classes = styles();

  const gallerySetting = useSetting([
    'gallery_img_1',
    'gallery_img_2',
    'gallery_img_3',
    'gallery_img_4',
    'gallery_img_5',
    'gallery_img_6',
    'gallery_img_7',
    'gallery_img_8',
    'gallery_img_9',

  ]);


  const [imagePopup, setImagePopup] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(0);

  const tileData = [
    {
      img: gallerySetting?.gallery_img_1,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_2,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: gallerySetting?.gallery_img_3,
      title: 'Image',
      author: 'author',
      cols: 3,
    },
    {
      img: gallerySetting?.gallery_img_4,
      title: 'Image',
      author: 'author',
      cols: 4,
    },
    {
      img: gallerySetting?.gallery_img_5,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_6,
      title: 'Image',
      author: 'author',
      cols: 1,
    },
    {
      img: gallerySetting?.gallery_img_7,
      title: 'Image',
      author: 'author',
      cols: 3,
    },
    {
      img: gallerySetting?.gallery_img_8,
      title: 'Image',
      author: 'author',
      cols: 2,
    },
    {
      img: gallerySetting?.gallery_img_9,
      title: 'Image',
      author: 'author',
      cols: 2,
    }
  ];


  const imagePreviewHandler = (idx) => {
    setImagePopup(true);
    setPhotoIndex(idx)
  }

  const getImageElements = () => {

    return tileData
      .filter(tile => tile.image !== null)
      .map((tile, idx) => (

        <GridListTile key={idx} cols={`${isMobile ? 6 : 2}`}>
          <Image
            onClick={() => imagePreviewHandler(idx)}
            animationDuration={3000}
            disableError={true}
            src={SETTING_FILE_PATH + tile.img}
            alt={tile?.title}
            className={classes.onHover}
          />
        </GridListTile>

      ))
      ;
  };

  return (<>

    <SlideAnimation in={true}>

      {
        !hideBredCrumb &&
        <PageHeader pageName="Our Gallery" />
      }

      {
        !hideBredCrumb &&
        <h3 className={`p-3 text-center ${Styles.backgroundDark} exo2`}>Our Gallery</h3>

      }

      <div className={classes.root}>
        <GridList cellHeight={400} className={classes.gridList} cols={6}>
          {
            getImageElements()
          }
        </GridList>

        {imagePopup && (
          <Lightbox

            mainSrc={SETTING_FILE_PATH + tileData[photoIndex]?.img}
            nextSrc={SETTING_FILE_PATH + tileData[(photoIndex + 1) % tileData.length]?.img}
            prevSrc={SETTING_FILE_PATH + tileData[(photoIndex + tileData.length - 1) % tileData.length]?.img}
            onCloseRequest={() => setImagePopup(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + tileData.length - 1) % tileData.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % tileData.length)
            }
          />
        )}
      </div>

    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  settings: state.settting,
});


export default connect(mapStateToProps)(GalleryPage);
