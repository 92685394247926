import React, { useEffect, useMemo, useReducer, useState } from 'react';
import * as dateFns from 'date-fns';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import TimePicker from '../../components/util/TimePicker/TimePicker';
import { DatePicker } from '@material-ui/pickers';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { apiRequest, applyTimeZone, getDateFormat, getTimeFormat, mergeDateTime } from '../../util/util';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';
import useNotify from '../../hooks/useNotify';
import useSetting from '../../hooks/useSetting';
import { API_ADD_RESERVATON, SETTING_FILE_PATH } from '../../util/constants';
// import CircularProgress from '@material-ui/core/CircularProgress';
import '../../assets/css/custom.scss';
import Image from 'material-ui-image';
import ScrollAnim from 'rc-scroll-anim';
import routes from "../../util/routes";



const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const defaultReservationFields = {
  name: '',
  email: '',
  persons: '',
  contact: '',
  bookingDate: '',
  bookingTime: '',
  requirements: '',
  heardOn: '',
}

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    name: false,
    email: false,
    persons: false,
    contact: false,
    bookingDate: false,
    bookingTime: false,
    heardOn: false,
  }
};


//handle address fields minimum errors
function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) ? payload : 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}


function ReservationComponent({ ...otherProps }) {

  const reservationSetting = useSetting([
    'reservation_img',
  ]);


  const [reservationBtnText, setReservationBtnText] = useState("Reservation");

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const [reservationFormState, reservationFormHandler] = useState({
    name: '',
    email: '',
    persons: '',
    contact: '',
    bookingDate: '',
    bookingTime: '',
    requirements: '',
    heardOn: '',
  });

  useEffect(() => {

    if (otherProps.user.data.name === "") return;

    reservationFormHandler({
      ...reservationFormState,
      name: otherProps.user.data.name,
      contact: otherProps.user.data.phone,
      email: otherProps.user.data.email,
    });


  }, [otherProps.user]);




  //notifaction hooks
  const [notify] = useNotify();

  const validationSchema = {
    name: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([6]),
      validationRules.max([30])
    ],
    email: [
      validationRules.required(),
      validationRules.email(),
    ],
    contact: [
      validationRules.required(),
      validationRules.containOnlyNumber(),
    ],
    persons: [
      validationRules.required(),
      validationRules.number(),
    ],
    requirements: [
      validationRules.min([8]),
      validationRules.max([50])
    ],
    heardOn: [
      validationRules.string(),
      validationRules.max([50])
    ]
  };

  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    reservationFormHandler({
      ...reservationFormState,
      [name]: value.toString()
    });


    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  }

  useEffect(() => {
    if (reservationFormState.name &&
      reservationFormState.contact &&
      reservationFormState.persons &&
      reservationFormState.email && selectedTime
    ) {
      setReservationBtnText("Submit");
    } else {
      setReservationBtnText("Reservation");
    }
  }, [formHandler]);

  const [loading, setLoading] = useState(false);


  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const [selectedDate, setSelectedDate] = useState(() => {
    return otherProps.date.length ? applyTimeZone(new Date(otherProps.date)) : applyTimeZone(new Date());
  });

  const selectedDaysHours = useMemo(() => {

    const selectedDayName = dateFns.format(selectedDate, 'EEE').toLowerCase();

    // set selected days hours
    const selectedDay = otherProps.opening.find(itm => itm.day === selectedDayName);

    const hours = selectedDay ? selectedDay.hours : [{ from: '00:00:00', to: '23:59:59' }];

    return hours;

  }, [selectedDate, otherProps.opening]);


  const [selectedTime, setSelectedTime] = useState(() => {
    const date = otherProps.date.length ? applyTimeZone(new Date(otherProps.date)) : applyTimeZone(new Date());

    // if date time available then set that
    if (otherProps.date.length) {
      return applyTimeZone(new Date(`${date.toDateString()} ${otherProps.time}`));
    }

    return false;
  });

  const openingDays = useMemo(() => {
    return otherProps.opening.map(itm => itm.day);
  }, [otherProps.opening]);


  const dayDisableHandler = day => {

    // enable all days if opening days not set
    if (openingDays.length === 0) {
      return false;
    }

    const dayName = dateFns.format(day, 'EEE').toLowerCase();

    // only enable days that have timing set
    return !openingDays.includes(dayName);
  };

  const [othersShow, setOthersShow] = useState(false);

  const [promotionalText, setPromotionalText] = useState('');

  const [otherText, setOtherText] = useState('');

  const handleOtherChoose = (event) => {
    setPromotionalText(event.target.value);
  };

  useEffect(() => {
    if (promotionalText === 'other') {
      setOthersShow(true);
    } else {
      setOthersShow(false);
      setOtherText('');
    }

  }, [promotionalText]);

  const getSelectedTime = () => {
    if (selectedTime === false) return '';
    let time = dateFns.format(selectedTime, getTimeFormat())
    return time;
  };

  const reservationSubmitHandler = async (e) => {

    e.preventDefault();

    const { error, data } = await formValidator(reservationFormState, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    if (reservationFormState.contact.toString().length < 7) {
      notify.warning("Contact Number should at least 7 digit");
      return;
    }

    if (!selectedTime) {
      notify.default('Please Select Reservation time');
      return;
    }

    let finalText;

    promotionalText !== 'other' ? finalText = promotionalText : finalText = reservationFormState.heardOn;

    setLoading(true);
    try {

      const mergeDateAndTime = mergeDateTime(selectedDate, selectedTime);
      const reservationData = ({
        name: reservationFormState.name,
        contact: reservationFormState.contact,
        persons: reservationFormState.persons,
        email: reservationFormState.email,
        note: reservationFormState.requirements,
        datetime: dateFns.format(mergeDateAndTime, 'yyyy-MM-dd HH:mm:ss'),
        found_on: finalText,
      });


      const reservationResponse = await apiRequest.post(API_ADD_RESERVATON, reservationData);

      if (reservationResponse.data.status) {
        reservationFormHandler(defaultReservationFields);

        setLoading(false);
        notify.success("Reservation Successful. You will notified shortly");

      } else {
        setLoading(false);
        notify.error(reservationResponse.data.messages);

      }

    } catch (e) {

      setLoading(false);
      notify.error("Something wrong. Please try later");

    }


  }

  const otherTextHandler = (e) => {
    setOtherText(e.target.value);
  }

  const ParallaxAnimation = ScrollAnim.Parallax;

  return (<>

    {/* features-4 */}
    <section className={`w3l-services-6-main w3l-contact-2 reservationSection backgroundDark`}>
      <div className="services-6 pt-5 pb-3">
        <div className="container">

          <div className="row serv_sec_info pt-5 pb-5">
            <div className="col-lg-6 banner_bottom_grid help">
              <Image animationDuration={3000} disableError={true} src={` ${SETTING_FILE_PATH + reservationSetting.reservation_img} `} className="img-fluid radius-image" alt="Reservation-image" />
            </div>


            <div className="col-lg-6 banner_bottom_left">


              <div className="contact-right">
                <h2 className="text-center reservation-title" color="primary"><u> Book A Table Online </u></h2>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', }}>
                  <a href={routes.reservation} className="read-more btn btn-style btn-primary" >
                   Make Reservation
                  </a>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </section>


  </>);

}

const mapStateToProps = state => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
});

export default connect(mapStateToProps)(ReservationComponent);
