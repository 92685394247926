const routes = {
    home: '/',

    // order: '/order',
    // orderConfirmation: '/order/confirmation',
    // orderSuccess: '/order/success',
    // orderHistory: '/order/history',

    gallery: '/gallery',
    about: '/about',
    // awards: '/awards',
    reservation: 'https://web.dojo.app/create_booking/vendor/lodvd6J5PUqnY7Aw9K6dSq0mU2RbftW7wn5clsOK3Ps_restaurant',
    contact: '/contact',
    // login: '/login',
    // forgetPassword: '/forget-password',
    // resetPassword: '/reset-password',
    // signup: '/signup',
    points: '/points',
    privacyPolicy: '/privacy-policy',
    termsConditions: '/terms-and-conditions',
    allergyInformation: '/allergy-information',
    userProfile: '/profile',
    manage: '/manage'
  };

  export default routes;
