import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { CURRENCY_SYMBOL } from '../../util/constants';
import { connect } from 'react-redux';
import { useStyles } from '../../pages/order/cart/cart.preview.style';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import routes from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function CartPreview({ ...otherProps }) {

  const classes = useStyles();
  //declare all props values


  let items = otherProps.cartItem;
  let itemAddons = otherProps.cartAddons;
  let OrderType = otherProps.orderType.type;
  let discount = otherProps.orderDiscount.value.toFixed(2);
  let coupon = otherProps.orderCoupon.value.toFixed(2);
  let deliveryCost = otherProps.orderDelivery.charge.toFixed(2);
  let totalItemCost = otherProps.totalItemCost.toFixed(2);
  let totalAddonsCost = otherProps.totalAddonsCost.toFixed(2);
  let pointAmount = otherProps.pointAmount.toFixed(2);
  let deliveryTime = otherProps.orderDelivery.time;
  let deliveryDate = otherProps.orderDelivery.date;
  let subTotal = (parseFloat(totalItemCost)) + (parseFloat(totalAddonsCost));
  let totalCost = (parseFloat(totalItemCost) +
    parseFloat(totalAddonsCost) +
    parseFloat(deliveryCost)) -
    (parseFloat(coupon) +
      parseFloat(discount) + parseFloat(pointAmount) - parseFloat(otherProps.adjustPointMoney));



  const cartAddons = (itemId, itemQty) => {
    return itemAddons
      .filter(addon => addon.item_id === itemId)
      .map(addon => (
        <Box
          key={addon.id}
          display="flex"
          justifyContent="space-between"

        >
          <Box>{addon.name}</Box>
          <Box>{CURRENCY_SYMBOL}{(addon.price * itemQty).toFixed(2)}</Box>
        </Box>
      ));
  };


  const [couponType, setCouponType] = useState("");

  const [popOpen, setPopOpen] = useState(null);

  const popupClick = (event) => {
    setPopOpen(event.currentTarget);
  };

  const popupClose = () => {
    setPopOpen(null);
  };

  const open = Boolean(popOpen);
  const id = open ? 'simple-popover' : undefined;

  const history = useHistory();

  useEffect(() => {

    if (otherProps.couponObject?.type === "") {
      setCouponType("");
      return;
    };

    if (otherProps.couponObject.type === "percent") {
      setCouponType(`(${otherProps.couponObject?.amount}%)`);
    } else {
      setCouponType(`(${CURRENCY_SYMBOL}${otherProps.couponObject?.amount})`);
    }

  }, [coupon]);

  const [discountType, setDiscountType] = useState("");

  const goPoints = () => {
    history.push(routes.points);
  }

  useEffect(() => {

    if (otherProps.discountObject?.type === "") {
      setDiscountType("");
      return;
    };

    if (otherProps.discountObject.type === "percent") {
      setDiscountType(`(${otherProps.discountObject?.amount}%)`);
    } else {
      setDiscountType(`(${CURRENCY_SYMBOL}${otherProps.discountObject?.amount})`);
    }

  }, [discount]);

  let itemList = items.map((item) => {
    return (
      <div className={classes.itemMargin} key={item.id}>
        <div className={classes.display}>
          <p className={`${classes.itemColor} ${classes.itemTextSize}`}>{`${item.qty} ${item.name}`}</p>
          <p className={`${classes.priceColor} ${classes.priceTextSize}`}>{`${CURRENCY_SYMBOL}${(item.price * item.qty).toFixed(2)}`}</p>
        </div>
        <div className={`${classes.addonTextSize} ${classes.addonColor} ${classes.spaceBetween}`}>
          {
            cartAddons(item.id, item.qty)
          }
        </div>
      </div>
    )
  })

  if (itemList.length) {
    return (<>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title="Cart Summary"

        />

        <CardContent>
          <div>

            <div className={`${classes.display}`}>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`Order Type: `}</p>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`${OrderType}`}</p>
            </div>

            <div className={`${classes.display} ${classes.marginBottom20}`}>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`Time: ${otherProps.asapTime ? 'ASAP' : deliveryTime}`}</p>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`Date: ${deliveryDate}`}</p>
            </div>


            {itemList}

            <div className={`${classes.display} ${classes.total}`}>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Item Total: </p>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`${CURRENCY_SYMBOL}${otherProps.totalItemCost.toFixed(2)}`}</p>
            </div>

            {
              itemAddons.length != 0 &&
              <div className={`${classes.display}`}>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Addons Total: </p>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`${CURRENCY_SYMBOL}${otherProps.totalAddonsCost.toFixed(2)}`}</p>
              </div>
            }

            <div className={`${classes.display} ${classes.total}`}>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Subtotal: </p>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`${CURRENCY_SYMBOL}${subTotal.toFixed(2)}`}</p>
            </div>

            {
              otherProps.discountObject.type != "" &&
              <div className={`${classes.display}`}>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Discount{discountType}: </p>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`- ${CURRENCY_SYMBOL}${discount}`}</p>
              </div>

            }

            {
              otherProps.couponObject.type != "" &&
              <div className={`${classes.display}`}>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Coupon{couponType}: </p>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`- ${CURRENCY_SYMBOL}${coupon}`}</p>
              </div>

            }

            {
              pointAmount > 0 &&
              <div className={`${classes.display}`}>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Points({otherProps.points}): </p>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`- ${CURRENCY_SYMBOL}${pointAmount}`}</p>
              </div>
            }


            <div className={`${classes.display}`}>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{OrderType} Charge: </p>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`+ ${CURRENCY_SYMBOL}${deliveryCost}`}</p>
            </div>

            {
              otherProps.adjustPointMoney !== 0 &&
              <div className={`${classes.display}`}>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Adjust Total:
                  <InfoIcon fontSize="large" color="primary" className="pop-over" onClick={popupClick}/></p>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={popOpen}
                  onClose={popupClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className="pop-over-text">As points always a number so here we adjusted {CURRENCY_SYMBOL}{otherProps.adjustPointMoney} points for this order. To know more checkout <a href="#" onClick={()=>goPoints()}>how it works</a></Typography>
                </Popover>
                <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`+${CURRENCY_SYMBOL}${otherProps.adjustPointMoney}`} </p>
              </div>
            }

            <div className={`${classes.display}`}>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>Total Amount: </p>
              <p className={`${classes.cartTitleSize} ${classes.cartTitleColor}`}>{`${CURRENCY_SYMBOL}${totalCost.toFixed(2)}`}</p>
            </div>

          </div>
        </CardContent>
      </Card>

    </>);
  } else {
    return (<>

      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title="Cart Summary"

        />

        <CardContent>
          <div>
            <h3>No Item in Cart</h3>
          </div>
        </CardContent>
      </Card>


    </>);
  }

}

const mapStateToProps = state => ({

  cartItem: state.cart.items,
  cartAddons: state.cart.addons,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderType: state.cart.order,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  couponObject: state.cart.coupon,
  discountObject: state.cart.discount,
  asapTime: state.cart.delivery.isAsapTime,
  pointAmount: state.cart.pointsToMoney,
  points: state.cart.orderPoint,
  adjustPointMoney: state.cart?.adjustPointMoney,

});


export default connect(mapStateToProps)(CartPreview);
